
import { Action, Getter } from "vuex-class";
import { Component, Ref, Vue } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";
import { bus } from "@/main";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormError,
        FormSubmit,
    },
})
export default class DeleteActivityModal extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Action("activity/delete") deleteActivity!: (id: number) => void;

    @Ref() form!: FormClass;

    payload = {};

    show = false;

    errorResponse: ErrorResponse = {
        status: 0,
        errors: [],
    };

    mounted() {
        bus.$off("show-delete-activity");
        bus.$on("show-delete-activity", () => {
            this.show = true;
        });
    }

    async submit(form: FormClass) {
        try {
            await this.deleteActivity(this.activity.id);

            this.$toast.open({ message: this.$t("views.prospects.deleted") as string, type: "success", position: 'bottom-left' });

            this.handleClose();
        } catch (e) {
            this.errorResponse = formatErrors(e);
        } finally {
            form.reset();
        }
    }

    handleClose() {
        this.errorResponse = {
            status: 0,
            errors: [],
        };

        this.show = false;
    }
}
